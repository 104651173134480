import React from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import Addblog from './Addblog';
import Profile from './Profile';
import Layout from '../Component/Layout';
import Updatesection from './Updatesection';
import Addservices from './Addservices';
import Prediction from './Prediction/Prediction';

const Clientsession = () => {
  return (
    <Layout>
      <Container>
        <Tabs defaultActiveKey="Prediction" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="Prediction" title="Prediction">
            <Prediction />
          </Tab>
          <Tab eventKey="Profile" title="Profile">
            <Profile />
          </Tab>
          <Tab eventKey="Blog" title="Blog">
            <Updatesection />
          </Tab>
          <Tab eventKey="Addservices" title="Add Services">
            <Addservices />
          </Tab>
        </Tabs>
      </Container>
    </Layout>
  );
};

export default Clientsession;
