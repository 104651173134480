import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import Layout from '../Component/Layout';

export default function Addblog() {


    const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');

  useEffect(() => {
    
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');

  
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [type, setType] = useState('image'); // default to 'image'
  const [file, setFile] = useState(null);
  const [source, setsource] = useState(''); // For text source
  
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleTextChange = (e) => {
    setsource(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create FormData to handle file uploads and text source
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('type', type);

    if (type === 'text' ||  type === 'youtubelink') {

      formData.append('source', source); // For text source
    } else if (file) {
      formData.append('source', file);
    }

    try {
      await axios.post('https://lunarsenterprises.com:5005/jyothisham/add/blog', formData, {
        headers: {
          api_key: userApiKey,
          user_id: userId,
        },
      });
      // Handle successful response
      toast.success('Blog added successfully!');
      setTitle('');
      setSubtitle('');
      setType('image');
      setsource('');
      setFile(null);
    } catch (err) {
      toast.error('Error adding blog.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container className="add-blog mt-5">
        <h2>Add Blog</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="subtitle">
            <Form.Label>Subtitle</Form.Label>
            <Form.Control
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="type">
            <Form.Label className='mt-2'>Type</Form.Label>
            <Form.Control
              as="select"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="image">Image</option>
              <option value="video">Video</option>
              <option value="youtubelink">Youtube Link</option>
              <option value="text">Description only</option>
            </Form.Control>
          </Form.Group>

          {type === 'image' && (
            <Form.Group controlId="file">
              <Form.Label className='mt-3'>Upload Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          )}
          {type === 'video' && (
            <Form.Group controlId="file">
              <Form.Label>Upload Video</Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          )}


          
          {type === 'text' && (
            <Form.Group controlId="source">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={source}
                onChange={handleTextChange}
                rows={4}
              />
            </Form.Group>
          )}


{type === 'youtubelink' && (
            <Form.Group controlId="source">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={source}
                onChange={handleTextChange}
                rows={4}
              />
            </Form.Group>
          )}

          <Button type="submit" variant="primary" disabled={loading} className='mt-3'>
            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Add Blog'}
          </Button>
        </Form>
        <ToastContainer />
      </Container>
    </Layout>
  );
}
