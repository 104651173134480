import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../Component/Layout';

const EditBlog = () => {
  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  const { b_id } = useParams();
  const [blog, setBlog] = useState({
    b_title: '',
    b_subtitle: '',
    b_type: '',
    b_source: '',
  });
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const headers = {
          headers: {
            api_key: userApiKey,
            user_id: userId,
          },
        };
        const response = await axios.get(`https://lunarsenterprises.com:5005/jyothisham/list/blog`, headers);
        const blogData = response.data.data.find((item) => item.b_id.toString() === b_id);
        if (blogData) {
          setBlog(blogData);
        }
      } catch (error) {
        console.error('Error fetching blog details:', error);
        toast.error('Failed to fetch blog details. Please try again.');
      }
    };

    fetchBlog();
  }, [b_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlog((prevBlog) => ({ ...prevBlog, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('b_id', b_id);
      formData.append('b_title', blog.b_title);
      formData.append('b_subtitle', blog.b_subtitle);
      formData.append('b_type', blog.b_type);
      if (file) {
        formData.append('b_source', file);
      } else {
        formData.append('b_source', blog.b_source);
      }

      const headers = {
        headers: {
          api_key: userApiKey,
          user_id: userId,
          'Content-Type': 'multipart/form-data',
        },
      };

      await axios.post('https://lunarsenterprises.com:5005/jyothisham/edit/blog', formData, headers);
      toast.success('Blog updated successfully!');
    } catch (error) {
      console.error('Error updating blog:', error);
      toast.error('Error updating blog. Please try again.');
    }
  };

  return (
    <Layout>

    
      <h2>Edit Blog</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBlogTitle">
          <Form.Label>Blog Title</Form.Label>
          <Form.Control
            type="text"
            name="b_title"
            value={blog.b_title}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBlogSubtitle">
          <Form.Label>Blog Subtitle</Form.Label>
          <Form.Control
            type="text"
            name="b_subtitle"
            value={blog.b_subtitle}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBlogType">
          <Form.Label>Blog Type</Form.Label>
          <Form.Control
            as="select"
            name="b_type"
            value={blog.b_type}
            onChange={handleInputChange}
            required
          >
            <option value="text">Text</option>
            <option value="image">Image</option>
            <option value="video">Video</option>
          </Form.Control>
        </Form.Group>
        {blog.b_type === 'image' && (
          <Form.Group controlId="formBlogSource">
            <Form.Label>Current Blog Image</Form.Label>
            {blog.b_source && <img src={`https://lunarsenterprises.com:5005/${blog.b_source}`} alt="Blog Image" className="img-fluid" />}
            <Form.Control
              type="file"
              name="b_source"
              onChange={handleFileChange}
            />
          </Form.Group>
        )}
        {blog.b_type === 'video' && (
          <Form.Group controlId="formBlogSource">
            <Form.Label>Current Blog Video</Form.Label>
            {blog.b_source && (
              <video controls className="img-fluid">
                <source src={`https://lunarsenterprises.com:5005/${blog.b_source}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <Form.Control
              type="file"
              name="b_source"
              onChange={handleFileChange}
            />
          </Form.Group>
        )}
        {blog.b_type === 'text' && (
          <Form.Group controlId="formBlogSource">
            <Form.Label>Blog Source</Form.Label>
            <Form.Control
              type="text"
              name="b_source"
              value={blog.b_source}
              onChange={handleInputChange}
            />
          </Form.Group>
        )}
        <Button variant="primary" type="submit" className='mt-5'>
          Save Changes
        </Button>
      </Form>
      <ToastContainer />
    </Layout>
  );
};

export default EditBlog;
