import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import '../Prediction/Prediction.css';

export default function Prediction() {
  const [horoscopeData, setHoroscopeData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState({});
  const [dateText, setDateText] = useState(""); // New state for dynamic date text
  const [cDescription, setCDescription] = useState(""); 
  const [selectedOption, setSelectedOption] = useState('daily'); // Defaulting to daily
  const [editData, setEditData] = useState({
    sign_name: '',
    star: '',
    sign_description: '',
    ww_time_period: '',
    ww_c_description: '',
  });

  // Modal handlers
  const handleShowModal = (box) => {
    setSelectedBox(box);
    setEditData({
      sign_name: box?.w_sign_name || '',
      star: box?.w_star || '',
      sign_description: box?.w_description || '',
      ww_time_period: box?.ww_time_period || '', // Get time period from the selected box
      ww_c_description: box?.ww_c_description || '', 
    });
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // Radio button change handler
  const handleOptionChange = (event) => setSelectedOption(event.target.value);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();

      // Append each field to FormData
      formData.append('w_id', selectedBox?.w_id); // Include ID from selected box
      formData.append('ww_id', selectedBox?.ww_id); // Include weekly horoscope ID
      formData.append('status', selectedOption); // daily, weekly, monthly
      formData.append('sign_name', editData.sign_name);
      formData.append('star', editData.star);
      formData.append('sign_description', editData.sign_description);
      formData.append('time_period', editData.ww_time_period);
      formData.append('common_description', editData.ww_c_description);
      
      // Send form data using axios
      const response = await axios.post(
        'https://lunarsenterprises.com:5005/jyothisham/edit/weekly/horoscope',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // This is required when sending FormData
          },
        }
      );

      console.log('Edit successful:', response.data);
      setShowModal(false); // Optionally close modal after success

      // Reload the horoscope data after saving
      fetchHoroscopeData();
    } catch (error) {
      console.error('Error editing horoscope data:', error);
    }
  };

  // Fetch data from API
  const fetchHoroscopeData = async () => {
    const Period = {
      status: selectedOption
    };
    try {
      const response = await axios.post(
        'https://lunarsenterprises.com:5005/jyothisham/list/weekly/horoscope',
        Period
      );
      setHoroscopeData(response.data.data);
      const dataItem = response.data.data[0]; // Get the first item
      
      if (dataItem) {
        setDateText(`${dataItem.ww_time_period} `);
        setCDescription(`${dataItem.ww_c_description}`);
      }
    } catch (error) {
      console.error('Error fetching horoscope data:', error);
    }
  };

  useEffect(() => {
    fetchHoroscopeData();
  }, [selectedOption]);

  // Get content based on selected option (daily/weekly/monthly)
  const getHoroscopeContent = () => {
    return (
      <div className="scrollable-container">
        <p>{dateText}</p>
        <p>{cDescription}</p>
        <div className="box-grid">
          {horoscopeData?.map((signData) =>
            signData.weekly.map((box) => {
              return (
                <div
                  className="box"
                  key={box.w_sign_name} // Unique key
                  onClick={() => handleShowModal(box)}
                >
                  <h3 className="Handlee">{box.w_sign_name} രാശി</h3>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="scrollable-container">
        <div className="radio-buttons">
          <label>
            <input
              type="radio"
              value="daily"
              className="m-2"
              checked={selectedOption === 'daily'}
              onChange={handleOptionChange}
            />
            ദിവസം ഫലം
          </label>
          <label>
            <input
              type="radio"
              value="weekly"
              className="m-2"
              checked={selectedOption === 'weekly'}
              onChange={handleOptionChange}
            />
            വാര ഫലം
          </label>
          <label>
            <input
              type="radio"
              value="monthly"
              className="m-2"
              checked={selectedOption === 'monthly'}
              onChange={handleOptionChange}
            />
            മാസ ഫലം
          </label>
        </div>
      </div>

      <div className="col-12">
        <div className="content-display">{getHoroscopeContent()}</div>
      </div>

      {/* Modal for editing horoscope data */}
      <Modal centered size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Horoscope - {editData.sign_name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group controlId="formSignName">
              <Form.Label>Sign Name</Form.Label>
              <Form.Control
                type="text"
                name="sign_name"
                value={editData.sign_name}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formStar">
              <Form.Label>Star</Form.Label>
              <Form.Control
                type="text"
                name="star"
                value={editData.star}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="sign_description"
                value={editData.sign_description}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formTimePeriod">
              <Form.Label>Time Period</Form.Label>
              <Form.Control
                type="text"
                name="ww_time_period"
                value={editData.ww_time_period}
                onChange={handleInputChange}
                disabled={horoscopeData[0]?.weekly[0]?.w_id !== selectedBox.w_id} // Disable if it's not the first index
              />
            </Form.Group>

            <Form.Group controlId="formCommonDescription">
              <Form.Label>Common Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="ww_c_description"
                value={editData.ww_c_description}
                onChange={handleInputChange}
                disabled={horoscopeData[0]?.weekly[0]?.w_id !== selectedBox.w_id} // Disable if it's not the first index
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
