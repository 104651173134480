import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Form, Container } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Imagefolder from "../img/Imagefolder.jpg";
import { Link } from "react-router-dom";

function Addservices() {
  const [userId, setUserId] = useState("");
  const [userApiKey, setUserApiKey] = useState("");

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    const storedUserApiKey = localStorage.getItem("user_api_key");

    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  const [servicesApi, setServicesApi] = useState([]);

  useEffect(() => {
    const servicesList = async () => {
      try {
        const servicesResp = await axios.get(
          "https://lunarsenterprises.com:5005/jyothisham/list/service"
        );
        setServicesApi(servicesResp.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    servicesList();
  }, []);

  const handleDelete = async (s_id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Service?");
    if (confirmDelete) {
      try {
        const headers = {
          headers: {
            api_key: userApiKey,
            user_id: userId,
          },
        };
        await axios.post(
          "https://lunarsenterprises.com:5005/jyothisham/remove/services",
          { s_id },
          headers
        );
        setServicesApi((prevServices) => prevServices.filter((service) => service.s_id !== s_id));
        toast.success("Service deleted successfully!");
      } catch (error) {
        console.error("Error deleting service:", error);
        toast.error("Error deleting service. Please try again.");
      }
    } else {
      toast.info("Service deletion cancelled.");
    }
  };

  const [formData, setFormData] = useState({
    service_name: "",
    service_description: "",
    image: null,
  });

  const [preview, setPreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      const file = files[0];
      setFormData({ ...formData, image: file });
      setPreview(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        api_key: userApiKey,
        user_id: userId,
      };

      const data = new FormData();
      data.append("service_name", formData.service_name);
      data.append("service_description", formData.service_description);
      if (formData.image) {
        data.append("image", formData.image);
      }

      const response = await axios.post(
        "https://lunarsenterprises.com:5005/jyothisham/add/service",
        data,
        { headers }
      );

      if (response.data && response.data.data) {
        const newService = response.data.data;
        setServicesApi((prevServices) => [...prevServices, newService]);
        toast.success("Service added successfully!");
      }

      setFormData({ service_name: "", service_description: "", image: null });
      setPreview(null);
    } catch (error) {
      console.error("Error adding service:", error);
      toast.error("Error adding service. Please try again.");
    }
  };

  return (
    <>
      <div className="mb-5 bg-white p-3">
        <Row className="">
          <Col md={6} className="p-3 ">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formImage" className="mt-3">
                <div className="centr">
                  {preview ? (
                    <img src={preview} alt="Selected Image" className="img-fluid mt-3" />
                  ) : (
                    <img src={Imagefolder} alt="Default Image" className="img-fluid mt-3" />
                  )}
                </div>
                <Form.Control type="file" name="image" className="mt-4" onChange={handleChange} />
              </Form.Group>
              <Form.Group className="mt-2" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="service_name"
                  value={formData.service_name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formDescription" className="mt-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="service_description"
                  value={formData.service_description}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Save
              </Button>
            </Form>
          </Col>
          <Col md={6} className="p-3 ">
            {servicesApi?.map((service, index) => (
              <div className="d-flex mt-3" key={index}>
                <p className="dropdown-itemser">{service.s_name}</p>
                <Link to={`/EditServicelist/${service.s_id}`} className="btnt mt-2 ml-2">
                  <FontAwesomeIcon icon={faPenToSquare} /> Edit
                </Link>
                <Button
                  variant="danger"
                  className="ml-2 ml-4 btnstyleservices"
                  onClick={() => handleDelete(service.s_id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            ))}
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
}

export default Addservices;
