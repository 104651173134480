import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../Component/Layout';

const EditService = () => {
  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  const { s_id } = useParams();
  const [service, setService] = useState({
    s_name: '',
    service_description: '',
    image: '',
  });
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    const fetchService = async () => {
      try {
        const headers = {
          headers: {
            api_key: userApiKey,
            user_id: userId,
          },
        };
        const response = await axios.get(
          `https://lunarsenterprises.com:5005/jyothisham/list/service`,
          headers
        );
        const serviceData = response.data.data.find((item) => item.s_id.toString() === s_id);
        if (serviceData) {
          setService(serviceData);
          setPreview(`https://lunarsenterprises.com:5005/${serviceData.s_image}`);
        }
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    if (userId && userApiKey) {
      fetchService();
    }
  }, [userId, userApiKey, s_id]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      const file = files[0];
      setFile(file);
      setPreview(URL.createObjectURL(file));
    } else {
      setService({ ...service, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        headers: {
          api_key: userApiKey,
          user_id: userId,
        },
      };

      const data = new FormData();
      data.append('s_id', s_id);
      data.append('s_name', service.s_name);
      data.append('service_description', service.s_description);
      if (file) {
        data.append('image', file);
      }

      const response = await axios.post(
        `https://lunarsenterprises.com:5005/jyothisham/edit/services`,
        data,
        headers
      );

      if (response.data && response.data.result) {
        toast.success('Service updated successfully!');
      }
    } catch (error) {
      console.error('Error updating service:', error);
      toast.error('Error updating service. Please try again.');
    }
  };

  return (
    <Layout>
      <Container>
        <h2>Edit Service</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formImage" className="mt-3">
            <div className="centr">
              {preview ? (
                <img src={preview} alt="Selected Image" className="img-fluid mt-3" />
              ) : (
                <img
                  src={`https://lunarsenterprises.com:5005/${service.s_image}`}
                  alt="Service"
                  className="img-fluid mt-3"
                />
              )}
            </div>
            <Form.Control
              type="file"
              name="image"
              className="mt-4"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mt-2" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="s_name"
              value={service.s_name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formDescription" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="s_description"
              value={service.s_description}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Save Changes
          </Button>
        </Form>
        <ToastContainer />
      </Container>
    </Layout>
  );
};

export default EditService;
