import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Layout from '../Component/Layout';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Profile() {
  const [teamlist, setteamlist] = useState([]);

  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');

  useEffect(() => {
    
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');

  
    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  useEffect(() => {
    Team();
  }, []);

  const Team = async () => {
    try {
      const headers = {
        headers: {
          api_key: userApiKey,
          user_id: userId,
        },
      };
      const Teamresp = await axios.get('https://lunarsenterprises.com:5005/jyothisham/list/team', headers);
      setteamlist(Teamresp.data.data || []); // Ensure teamlist is always an array
      console.log(Teamresp.data, 'Teamrespdata');
    } catch (error) {
      console.error('Error fetching team list:', error);
      toast.error('Failed to fetch team members. Please try again.');
    }
  };

  const handleDelete = async (t_id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this profile?');
    if (confirmDelete) {
      try {
        const headers = {
          headers: {
            api_key: userApiKey,
            user_id: userId,
          },
        };
        await axios.post('https://lunarsenterprises.com:5005/jyothisham/delete/team', { t_id }, headers);
        setteamlist((prevTeamList) => prevTeamList.filter((team) => team.t_id !== t_id));
        toast.success('Profile deleted successfully!');
      } catch (error) {
        console.error('Error deleting team member:', error);
        toast.error('Error deleting profile. Please try again.');
      }
    } else {
      toast.info('Profile deletion cancelled.');
    }
  };



  



  return (
    <div className='mb-5'>

      <div>
        <Button href="/Client/Addprofile" className="addprofile">
          <FontAwesomeIcon icon={faPlus} /> Add Profile
        </Button>
      </div>

      {teamlist.length > 0 ? (
        <Row className="gy-4">
          {teamlist.map((card, index) => (
            <Col key={index} xs={12} sm={6} md={6} lg={4}>
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  className="card-img-contain"
                  src={`https://lunarsenterprises.com:5005/${card.t_image}`}
                  alt={`Profile image ${index}`}
                />
                <Card.Body className="bodycolor">
                  <Card.Title>{card.t_name}</Card.Title>
                  <Card.Text>{card.t_designation}</Card.Text>
                  <Link to={`/profile/${encodeURIComponent(card.t_name)}`} className="btn btn-primary">
                    See Profile
                  </Link>
                  <Button
                    variant="danger"
                    className="ml-2 deletebuttonpro"
                    onClick={() => handleDelete(card.t_id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <p className='mt-5'>No Profile Found</p> // Display this message if there are no profiles
      )}
      <ToastContainer />
    </div>
  );
}

export default Profile;
