import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Layout from '../Component/Layout';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Updatesection() {

    const [userId, setUserId] = useState('');
    const [userApiKey, setUserApiKey] = useState('');
  
    useEffect(() => {
      
      const storedUserId = localStorage.getItem('user_id');
      const storedUserApiKey = localStorage.getItem('user_api_key');
  
    
      if (storedUserId) {
        setUserId(storedUserId);
      }
      if (storedUserApiKey) {
        setUserApiKey(storedUserApiKey);
      }
    }, []);

  const [teamlist, setteamlist] = useState([]);

  useEffect(() => {
    Team();
  }, []);

  const baseUrl = 'https://lunarsenterprises.com:5005/'; 

  const Team = async () => {
    try {
      const headers = {
        headers: {
          api_key: userApiKey,
          user_id: userId,
        },
      };
      const Teamresp = await axios.get(`${baseUrl}jyothisham/list/blog`, headers);
      setteamlist(Teamresp.data.data || []); // Ensure teamlist is always an array
      console.log(Teamresp.data, 'Teamrespdata');
    } catch (error) {
      console.error('Error fetching team list:', error);
      toast.error('Failed to fetch team members. Please try again.');
    }
  };

  const handleDelete = async (b_id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this blog?');
    if (confirmDelete) {
      try {
        const headers = {
          headers: {
            api_key: userApiKey,
            user_id: userId,
          },
        };
        await axios.post('https://lunarsenterprises.com:5005/jyothisham/delete/blog', { b_id }, headers);
        setteamlist((prevTeamList) => prevTeamList.filter((team) => team.b_id !== b_id));
        toast.success('Blog deleted successfully!');
      } catch (error) {
        console.error('Error deleting blog:', error);
        toast.error('Error deleting blog. Please try again.');
      }
    } else {
      toast.info('Blog deletion cancelled.');
    }
  };

  const getYouTubeVideoId = (url) => {
    if (!url) return ""; // Return an empty string if the URL is null or undefined
    const urlParts = url.split("v=");
    return urlParts.length > 1 ? urlParts[1].split("&")[0] : ""; // Extract the video ID from the URL
  };
  

  return (
    <div className='mb-5'>
      <div>
        <Button href="/Addblog" className="addprofile">
          <FontAwesomeIcon icon={faPlus} /> Add Blog
        </Button>
      </div>

      

      {teamlist.length > 0 ? (
        <Row className="gy-4">
          {teamlist.map((card, index) => {
            const mediaUrl = `${baseUrl}${card.b_source}`;
            return (
              <Col key={index} xs={12} sm={6} md={6} lg={4}>
                <Card className="h-100">
                  {card.b_type === "image" && (
                    <Card.Img
                      variant="top"
                      className="card-img-contain"
                      src={mediaUrl}
                      alt={`Blog image ${index}`}
                    />
                  )}
                  {card.b_type === "video" && (
                    <video controls className="card-img-contain">
                      <source src={mediaUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}

{card.b_type === "youtubelink" && (
                          <div className="boxvideozodiablog">
                            <iframe
                              width="100%"
                              height="200"
                              src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                                card.b_source
                              )}`}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerpolicy="strict-origin-when-cross-origin"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )}
                  {card.b_type === "text" && (
                    <Card.Body className="bodycolor">
                      <Card.Text>{card.b_source}</Card.Text>
                    </Card.Body>
                  )}
                  <Card.Body className="bodycolor ">
                    <Card.Title>{card.b_title}</Card.Title>
                    <Card.Text>{card.b_subtitle}</Card.Text>
                    <div className=' d-flex'>
                    <Link to={`/editblog/${card.b_id}`} className="btn btn-primary">
                    <FontAwesomeIcon icon={faPenToSquare} /> Edit Blog
                    </Link>
                    <Button
                      variant="danger"
                      className="  buttonconst"
                      onClick={() => handleDelete(card.b_id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : (
        <p className='mt-5'>No Blogs Found</p> // Display this message if there are no blogs
      )}
      <ToastContainer />
    </div>
  );
}

export default Updatesection;
