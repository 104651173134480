import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "../src/css/main.scss";
import "../src/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Sidebar from "./Component/Sidebar";
import Header from "./Component/Header";
import SignIn from "./Screens/Signin";
import AppState from "./context/Context";
import Profile from "./Screens/Profile";
import TeamMemberProfile from "./Screens/TeamMemberProfile";
import Addprofile from "./Screens/Addprofile";
import Updatesection from "./Screens/Updatesection";
import Addblog from "./Screens/Addblog";
import Addservices from "./Screens/Addservices";
import Clientsession from "./Screens/Clientsession";
import Dashboard from "./Screens/Dashboard";
import Editblog from "./Screens/Editblog";
import ServiceDetail from "./Screens/[servicesview]";
import EditServicelist from "./Screens/[Editserviceslist]";





function Main() {
  const location = useLocation();
  const currPath = location.pathname;

  return (
    <div className="">
      {/* Sidebar and Header */}
      {currPath !== "/SignIn" && (
        <>
          <Sidebar />
          <Header />
        </>
      )}

      {/* Content */}
      <div className="">
        <Routes>
          <Route path="/" element={<Navigate to="/SignIn" />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/Dashboard" element={<Dashboard />} />  
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Client/session" element={<Clientsession />} />
          <Route path="/Client/Addprofile" element={<Addprofile />} />
          <Route path="/Update/section" element={<Updatesection />} />
          <Route path="/Addblog" element={<Addblog />} />
          <Route path="/Add/services" element={<Addservices />} /> 
          <Route path="/editblog/:b_id" element={<Editblog />} /> 


          <Route path="profile/:name" element={<TeamMemberProfile />} />  
          <Route path="/services/:service" element={<ServiceDetail />} /> 

       

       <Route path="/EditServicelist/:s_id" element={<EditServicelist />} /> 

        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <AppState>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </AppState>
  );
}

export default App;
