import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import Layout from '../Component/Layout';
import { Col, Container, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import styles for react-toastify

function TeamMemberProfile() {
  const [userId, setUserId] = useState('');
  const [userApiKey, setUserApiKey] = useState('');

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    const storedUserApiKey = localStorage.getItem('user_api_key');

    if (storedUserId) {
      setUserId(storedUserId);
    }
    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  const { name } = useParams(); // Get the team member's name from the URL
  const [member, setMember] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    t_name: '',
    t_designation: '',
    t_description: '',
    t_image: ''
  });
  const [preview, setPreview] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const response = await axios.get('https://lunarsenterprises.com:5005/jyothisham/list/team');
        const memberData = response.data.data.find((item) => item.t_name === name);
        if (memberData) {
          setMember(memberData);
          setFormData({
            t_name: memberData.t_name,
            t_designation: memberData.t_designation,
            t_description: memberData.t_description,
            t_image: memberData.t_image
          });
          setPreview(`https://lunarsenterprises.com:5005/${memberData.t_image}`);
        }
      } catch (error) {
        console.error('Error fetching team member data:', error);
      }
    };

    fetchMember();
  }, [name]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('t_id', member.t_id); 
    formDataToSubmit.append('t_name', formData.t_name);
    formDataToSubmit.append('t_designation', formData.t_designation);
    formDataToSubmit.append('t_description', formData.t_description);
    if (formData.t_image) {
      formDataToSubmit.append('t_image', formData.t_image);
    }

    try {
      const response = await axios.post(
        'https://lunarsenterprises.com:5005/jyothisham/edit/team',
        formDataToSubmit,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'user_id': userId,
            'api_key': userApiKey,
          }
        }
      );

      if (response.data.result) {
        toast.success('Update successful!'); // Show success message
        setEditMode(false);
        // Navigate to the /profile route
        navigate('/profile');
      } else {
        toast.error(`Update failed: ${response.data.message}`); // Show error message
      }
    } catch (error) {
      toast.error('Error updating team member.'); // Show error message
      console.error('Error updating team member:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 't_image') {
      const file = files[0];
      setFormData({ ...formData, t_image: file });
      setPreview(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  if (!member) return <p>Loading...</p>;

  return (
    <Layout>
      <Container>
        {editMode ? (
          <div>
            <Col md={6} className='p-3'>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formImage" className="mt-3">
                  <div className='centr'>
                    {preview && (
                      <img src={preview} alt="Selected Image" className="img-fluid mt-3" />
                    )}
                  </div>
                  <Form.Control
                    type="file"
                    name="t_image"
                    className="mt-4"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formName" className='mt-5'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="t_name"
                    value={formData.t_name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formDesignation" className="mt-3">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    type="text"
                    name="t_designation"
                    value={formData.t_designation}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formDescription" className="mt-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="t_description"
                    value={formData.t_description}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3 ">
                  Save
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setEditMode(false)}
                  className="mt-3 ms-2 borderr"
                >
                  Cancel
                </Button>
              </Form>
            </Col>
          </div>
        ) : (
          <Col md={12} className='bg-light p-3 centrerstylr mt-5'>
            <div className='mt-5'>
              <img src={preview || `https://lunarsenterprises.com:5005/${member.t_image}`} alt={member.t_name} className="img-fluid" width={350} height={100} />
              <h4 className='mt-5'>Name : {member.t_name}</h4>
              <h5 className='mt-2'>Designation : {member.t_designation}</h5>
              <p className='mt-2'>Description : {member.t_description}</p>

              <Button variant="primary" className='mt-5 editbutton' onClick={() => setEditMode(true)}>
                <FontAwesomeIcon icon={faPenToSquare} /> Edit
              </Button>
            </div>
          </Col>
        )}
      </Container>
      <ToastContainer /> {/* Add ToastContainer for react-toastify */}
    </Layout>
  );
}

export default TeamMemberProfile;
