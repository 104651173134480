import React, { createContext, useState } from "react";

export const Context = createContext();

const AppState = ({ children }) => {
  const [show, setShow] = useState(false);

  return (
    <Context.Provider value={{ show, setShow }}>
      {children}
    </Context.Provider>
  );
};

export default AppState;
