import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Layout from '../Component/Layout';
import './ServiceDetails.css'; // Add a CSS file for custom styling

const ServiceDetail = () => {
  const { service } = useParams();
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    if (service) {
      const fetchServiceData = async () => {
        try {
          const response = await axios.get('https://lunarsenterprises.com:5005/jyothisham/list/service');
          const foundService = response.data.data.find(s => s.s_name.toLowerCase().replace(/\s+/g, '-') === service.toLowerCase());
          setServiceData(foundService);
        } catch (error) {
          console.error(error);
        }
      };

      fetchServiceData();
    }
  }, [service]);

  if (!serviceData) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <Layout>
      <div className="service-detail-wrapper">
        <div className="herobanner">
          <div className="container text-center">
            <h1 className="service-heading">{serviceData.s_name}</h1>
          </div>
        </div>
        <div className="container service-detail-content mt-5 mb-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <img
                src={`https://lunarsenterprises.com:5005/${serviceData.s_image}`}
                alt={serviceData.s_name}
                className="service-image img-fluid"
              />
            </div>
            <div className="col-12 col-md-6 service-info">
              <h2>{serviceData.s_name}</h2>
              <p>{serviceData.s_description}</p>
              <span className="status">Status: {serviceData.s_status}</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServiceDetail;
