import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import Layout from '../Component/Layout';

const Dashboard = () => {
  const [blogCount, setBlogCount] = useState(0);
  const [teamCount, setTeamCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);

  // Mock API calls to get counts
  useEffect(() => {
    // Simulate API calls for counts
    setBlogCount(12);  // For example, you get 12 blogs from API
    setTeamCount(8);   // Example team count
    setServiceCount(5); // Example service count
  }, []);

  const data = [
    { title: "Blog", count: blogCount, color: "#ffcccb" },
    { title: "Team", count: teamCount, color: "#add8e6" },
    { title: "Service", count: serviceCount, color: "#90ee90" }
  ];

  return (
    <Layout>
      <Box
        sx={{
        
        }}
      >
        <Grid container spacing={4}  >
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card 
                style={{ 
                  backgroundColor: item.color, 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                  borderRadius: '10px', 
                  // padding: '20px',
                  textAlign: 'center'
                }}
              >
                <CardContent>
                  <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="h3" style={{ marginTop: '20px', fontWeight: '500' }}>
                    {item.count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};

export default Dashboard;
